@import "variables";

.offcanvas-menu {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  .offcanvas-header {
    .App-logo {
      max-width: 200px;
    }
  }

  .offcanvas-body {
    .App-logo {
      height: 40px;
      margin-bottom: 1.5rem;
      margin-left: 0.5rem;
      pointer-events: none;
    }
    .dropdown-toggle {
      svg {
        display: inline-block;
      }
    }

    .navbar-nav {
      .nav-link {
        font-weight: $bold;
        color: $black;
        font-size: 14px;
      }

      .hub-link {
        background-color: $primary-colour;
        border-radius: 6px;

        .nav-link {
          color: $white;
        }
      }
    }
  }

  .offcanvas-footer {
    button {
      width: 100%;
    }
  }
}