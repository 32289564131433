@import "variables";

.error-boundary-message {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-200px);
  padding: 2rem;
  box-shadow: 2px 2px 4px 4px $grey;
  border-radius: 5px;

  @include breakpoint(md) {
    max-width: 430px;
  }

  h1 {
    margin-bottom: 0.75rem;
    font-size: 18px;
    color: $primary-colour;
    font-weight: $bold;
  }

  p {
    margin-bottom: 0.75rem;
  }

  .error-boundary-footer {
    display: flex
  }

  // button {
  //   border: 2px solid $primary-colour;
  //   border-radius: 3px;
  //   background-color: $primary-colour;
  //   color: $white;
  //   padding: 0.5rem 0.75rem;
  //   font-weight: $bold;
  //   transition: 0.25s ease-in-out all;

  //   &:hover {
  //     border: 2px solid $primary-colour;
  //     background: transparent;
  //     color: $primary-colour;
  //   }
  // }
}