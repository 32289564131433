@import "variables";

.App-header {
  #main-navbar {
    .nav-container {
      background-color: $white;
    }

    .App-logo {
      height: 40px;
      margin-right: 1.5rem;
      pointer-events: none;
    }

    .navbar-collapse {
      background-color: $white;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,0.5);
      border-radius: 0 0 4px 4px;

      @include breakpoint (md) {
        box-shadow: none;
      }
    }

    .nav-item {
      .nav-link {
        font-weight: $bold;
        color: $black;
        font-size: 14px;
        position: relative;

        &:hover {
          color: $primary-colour;
        }

        &.active {
          color: $primary-colour;
        }

        &.dropdown-toggle {
          svg {
            display: inline-block;
          }
        }

        &.dropdown-item {
          &.active, &:active, &:focus {
            background: transparent
          }
        }
      }

      &.hub-link {
        background-color: $primary-colour;
        border-radius: 6px;

        .nav-link {
          color: $white;
        }
      }
    }
  }

  .settings-dropdown {
    .settings-btn {
      border: 2px solid $darkgrey;
      color: $darkgrey;

      svg {
        display: inline-block;
      }

      &:hover {
        border-color: $primary-colour;
        color: $primary-colour;

        svg {
          animation: spin-animation 2.5s infinite
        }
      }
    }
  }

  .logout-button{
    // padding: 0.5rem 1.5rem !important;

    .logout-icon {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-left: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-box-arrow-in-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z'/%3E%3Cpath fill-rule='evenodd' d='M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'/%3E%3C/svg%3E");
      background-size: contain;
      vertical-align: middle;
      color: $white;
    }

    &:hover {
      border: 2px solid $primary-colour;
      background-color: transparent;
      color: $primary-colour;

      .logout-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='red' class='bi bi-box-arrow-in-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z'/%3E%3Cpath fill-rule='evenodd' d='M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'/%3E%3C/svg%3E");
      }
    }
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}