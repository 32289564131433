@import "variables";

.main-container {
  margin: 0 auto;
  min-height: calc(100vh - 10vh - 56px);

  @include breakpoint(xl) {
    padding: 30px 15px;
    max-width: 90vw;
  }
}
.contact-details {
  border-left: 4px solid #00adee;
  padding-left: 16px;
  margin-top: 16px;
}
.icon-black {
  filter: grayscale(100%) brightness(0);
}
.App-footer {
  height: 10vh;
  background-color: #152733;
  color: $white;
  text-align: center;

  @include breakpoint(xl) {
    margin-top: 0;
  }

  p {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 0;
    font-size: 12px;

    @include breakpoint(xl) {
      width: 100%;
    }
  }
}

.loader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    width: 90vw;
    height: 0.5rem;
    margin: 0 auto;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    background-color: $grey;

    @include breakpoint(lg) {
      width: 500px;
    }

    .loader-bar {
      width: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 9999px;
      background-color: $primary-colour;
      animation: borealisBar 2s linear infinite;
    }
  }
}

@keyframes borealisBar {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}

.session-expired-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
  opacity: 1;
  z-index: 1000;
  transition: 120ms all ease-in-out;
}

.session-expired-warning-wrapper {
  position: fixed;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-250px, -100px);
  width: 500px;
  border-radius: 5px;
  background-color: #fff;
  visibility: visible;
  opacity: 1;
  z-index: 1001;
  transition: 120ms all ease-in-out;
  padding: 1rem 1.5rem;

  h5 {
    margin-bottom: 1rem;
    color: $primary-colour;
    font-size: 1.5rem;
    font-weight: $bold;
  }

  p {
    margin-bottom: 2rem;

    span {
      color: $primary-colour;
    }
  }
}

.text-red {
  color: $primary-colour;
}

.btn {
  border-radius: 0.25rem;
  border: 2px solid $darkgrey;
  background-color: $darkgrey;
  color: $white;
  transition: 0.25s ease-in-out all;

  &:hover {
    border: 2px solid $darkgrey;
    background-color: transparent;
    color: $darkgrey;
  }

  &:disabled {
    opacity: 0.5;
    background-color: $darkgrey;
    color: $white;
    cursor: wait;
  }

  &.red-btn {
    border: 2px solid $primary-colour;
    background-color: $primary-colour;
    color: $white;

    &:hover {
      border: 2px solid $primary-colour;
      background: transparent;
      color: $primary-colour;
    }

    &:disabled {
      opacity: 0.5;
      background-color: $primary-colour;
      color: $white;
      cursor: wait;
    }
  }

  &.border-btn {
    border: 2px solid $darkgrey;
    background-color: transparent;
    color: $darkgrey;

    &:hover {
      border-color: $primary-colour;
      color: $primary-colour;
    }

    &:disabled {
      opacity: 0.5;
      background-color: transparent;
      color: $darkgrey;
      cursor: wait;
    }
  }

  &.clear-btn {
    border: none;
    background-color: transparent;
    color: $black;
    font-weight: normal;

    &:hover {
      color: $primary-colour;
    }

    &:disabled {
      opacity: 0.5;
      background-color: transparent;
      color: $black;
      cursor: wait;
    }
  }

  &.sml-btn {
    font-size: 13px;
  }

  &.round-btn {
    border-radius: 4rem;
  }
}

.input-required {
  color: $primary-colour;
}

.authenticate-wrapper {
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 3px 4px $grey;
  border-radius: 3px;

  .input-authentication {
    input {
      height: 45px;
      border: 1px solid #dfe1df;
      border-radius: 3px;
    }
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;

  & > div {
    width: 10px;
    height: 10px;
    margin: 0px 6px;
    border-radius: 50%;
    background-color: $white;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  &:hover {
    & > div {
      background-color: $primary-colour;
    }
  }
}

.update-loader {
  background-color: rgba(93, 95, 103, 0.8);
  z-index: 100;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $blue;
      border-color: $blue transparent $blue transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-3px);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
