/* DisableOptions.scss */

/* Wrapper for the entire disable options section */
.disable-options-wrapper {
  padding: 20px;
}

/* Container for the grid of options */
.options-grid-container {
  margin-top: 20px;
}

/* Styles for the grid header */
.options-grid-header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;

  h3 {
    margin: 0;
    font-weight: bold;
  }
}

/* Styles for each option row */
.option-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 15px 0;

  p {
    margin: 0;
  }
}

/* Styles for the option name column */
.option-row > div:first-child {
  display: flex;
  align-items: center;
}

/* Styles for the action column */
.option-row > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Button styles */
.disable-btn {
  background-color: #f44336; /* Red color for disable action */
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.disable-btn:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Disabled text style */
.option-row p {
  color: #757575;
}

/* Footer styles */
.options-grid-footer {
  margin-top: 20px;

  p {
    margin: 0;
    font-style: italic;
    color: #757575;
  }
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .options-grid-header,
  .option-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .options-grid-header > div,
  .option-row > div {
    width: 100%;
  }

  .option-row > div:last-child {
    margin-top: 10px;
  }
}
