$primary-colour: #ff0000;
$white: #fefefe;
$light-grey: #e2eced;
$grey: #dfe1df;
$darkgrey: #5d5f67;
$black: #333333;
$light-blue: #f0fafd;
$blue: #71cfeb;
$bold: 600;
$success: #08b502;
$pending: #ffea00;

$breakpoints: (
  'sm' : ( screen and min-width: 640px ),
  'md' : ( screen and min-width: 768px ),
  'lg' : ( screen and min-width: 1024px ),
  'xl' : ( screen and min-width: 1280px ),

) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Password strength meter color for the different levels
$strength-colors: (darkred, orangered, orange, yellowgreen, green);

// Gap width between strength meter bars
$strength-gap: 6px;