@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Regular-Web.eot');
  src: url('../fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Graphik-Regular-Web.woff') format('woff'),
      url('../fonts/Graphik-Regular-Web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Semibold-Web.eot');
  src: url('../fonts/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Graphik-Semibold-Web.woff') format('woff'),
      url('../fonts/Graphik-Semibold-Web.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Graphik', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
